@import "../../../styles/colors.scss";
@import "../../../styles/typographies.scss";
@import "../../../styles/custom.scss";
.o-final-container__modal {
  &__inside {
    &__image {
      height: 100px;

      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__profile-name {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      text-align: center;
      h2 {
        margin: 0;
        color: var(--primary-dark-color);
        @include TTCommonsMedium;
        font-size: 30px;
        font-weight: 500;
        line-height: 31px;
      }
      p {
        margin: 0;
        color: var(--gray-text-color);
        @include TTCommonsRegular;
        font-size: 18px;
        line-height: 21px;
      }
    }
    &__tags {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 10px;
      &__item {
        height: 25px;
        width: auto;
        border-radius: 100px;
        background-color: $color-grey-6;
        padding-inline: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          margin: 0 0 0 0;
          color: var(--primary-dark-color);
          @include TTCommonsRegular;
          font-size: 15px;
          line-height: 15px;
          height: 10px;
        }
      }
    }
    &__profile-tittle {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 7px;
      margin-bottom: 10px;

      p {
        margin: 0;
        color: var(--primary-dark-color);
        @include TTCommonsRegular;
        font-size: 18px;
        font-weight: 500;
        line-height: 19px;
      }
    }
    &__profile-detail {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 10px;
      p {
        margin: 0;
        color: var(--primary-dark-color);
        @include TTCommonsMedium;
        font-size: 18px;
        font-weight: 500;
        line-height: 21px;
        text-align: center;
      }
    }
    &__profile-summary {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        p {
          margin: 0;
          color: var(--primary-dark-color);
          @include TTCommonsMedium;
          font-size: 18px;
          font-weight: 500;
          line-height: 21px;
          text-align: center;
        }
        span {
          font-size: 16px;
          line-height: 14px;
          color: var(--primary-dark-color);
          font-family: "TTCommons-Medium";
          font-weight: 500;
          text-decoration: none;
          border-bottom: 1px solid var(--primary-dark-color);
          cursor: pointer;
          padding-top: 3px;
          transition: all 0.3s;
        }
      }
    }
    &__residence {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      margin-bottom: 15px;

      &__area {
        display: flex;
        justify-content: center;
        gap: 7px;
        p {
          margin: 0;
          color: var(--primary-dark-color);
          @include TTCommonsRegular;
          font-size: 18px;
          line-height: 20px;
        }
      }
    }
    &__profile-about {
      text-align: center;
      margin-bottom: 10px;
      span {
        margin-left: 5px;
        cursor: pointer;
        color: $color-orange;
      }

      p {
        margin: 0;
        max-width: 381px;
        color: var(--primary-dark-color);
        @include TTCommonsRegular;
        font-size: 18px;
        margin: auto;
        line-height: 20px;
        text-align: center;
      }
    }
    &__profile-linkedin {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      flex-direction: column;
      img {
        width: 40px;
        cursor: pointer;
        height: 40px;
        border-radius: 50%;
      }
    }
    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
