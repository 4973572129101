.student-services {
  margin-bottom: 50px;
  &__title {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      color: #000c3d;
      font-family: "TTCommons-DemiBold";
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      margin: 0;
    }
  }
  &__listing-services {
    display: flex;
    align-items: center;
    justify-content: center;
    &__button {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 20px;
      padding-right: 20px;
      width: 245px;
      height: 50px;
      border-radius: 25px;
      background-color: #ffffff;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      text-decoration: none;
      span {
        height: 17px;
        margin-left: 5px;
        color: #8094ab;
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 20px;
      }
      img {
        width: 20px;
      }
    }
  }
  &__saved-services {
    display: flex;
    align-items: center;
    justify-content: center;
    &__button {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-inline: 20px;
      width: 285px;
      height: 50px;
      border-radius: 25px;
      background-color: #ffffff;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      span {
        margin-inline: 5px;
        color: #000c3d;
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 20px;
        height: 17px;
      }
    }
  }
  &__title-search-spacer {
    margin-top: 17px;
  }
  &__search {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    > div {
      margin-bottom: 0px;
    }
    &__button {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-left: 10px;
      &:focus {
        opacity: 0.9;
        box-shadow: 0 0 0px 3px var(--gray-outline-color) !important;
        border-radius: 45px;
      }
    }
    &__clear {
      position: relative;
      span {
        position: absolute;
        top: -10px;
        left: 20px;
        @media (max-width: 455px) {
          top: 35px;
          left: -165px;
        }
        letter-spacing: 0;
        font-size: 18px;
        line-height: 16px;
        color: var(--gray-text-color);
        font-family: "TTCommons-Medium";
        font-weight: 500;
        text-decoration: none;
        border-bottom: 2px solid var(--gray-text-color);
        cursor: pointer;
        padding-top: 3px;
        white-space: nowrap;
        transition: all 0.3s;
        &:hover {
          opacity: 0.8;
        }
      }
      &:focus {
        span {
          color: var(--link-color) !important;
          border-color: var(--link-color) !important;
          opacity: 0.9 !important;
          outline: 2px solid var(--primary-dark-color) !important;
        }
      }
    }
  }
  &__categories--wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    > div {
      width: 100%;
    }
    @media (min-width: 320px) {
      justify-content: center;
      > div {
        width: 90%;
      }
    }
    @media (min-width: 375px) {
      justify-content: center;
      > div {
        width: 85%;
      }
    }
    @media (min-width: 375px) {
      justify-content: center;
      > div {
        width: 75%;
      }
    }
    @media (min-width: 500px) {
      justify-content: center;
      > div {
        width: 65%;
      }
    }
    @media (min-width: 600px) {
      justify-content: center;
      > div {
        width: 100%;
      }
    }
    @media (min-width: 1366px) {
      justify-content: flex-end;
      > div {
        width: 80%;
      }
    }
  }
  &__categories--loader {
    > div > div {
      margin-bottom: 10px;
    }
  }
  &__listing--loader {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-start;
    @media (max-width: 775px) {
      justify-content: center;
    }
  }
  &__no-data-placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2 {
      margin: 20px 0 0 0;
      color: #000c3d;
      font-family: "TTCommons-Regular";
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 21px;
      text-align: center;
    }
    h3 {
      margin: 8px 0 18px 0;
      color: #000c3d;
      font-family: "TTCommons-Regular";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
      text-align: center;
    }
  }
  &__categories {
    &__item {
      cursor: pointer;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      height: 50px;
      border-radius: 25px;
      background-color: #ffffff;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      padding: 10px 15px;
      text-decoration: none;
      transition: all 0.3s;
      &:hover {
        box-shadow: 0 1px 14px 3px var(--gray-outline-color);
      }
      &:focus {
        box-shadow: 0 1px 14px 3px var(--gray-outline-color);
      }
      &__image-wrapper {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        background-color: #f1f4f7;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        img {
          width: 100%;
        }
      }
      span {
        height: 16px;
        width: 80%;
        color: #000c3d;
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &__active {
        background-color: #000485;
        span {
          color: #ffffff;
        }
      }
    }
  }
  &__search-deals-spacer {
    margin-top: 26px;
  }
}
