/****** Header content ******/
.logos {
  .logo {
    &:focus {
      box-shadow: 0 0 0px 2px var(--gray-outline-color) !important;
    }
  }
}
.header-rightsidebar {
  li {
    a {
      &:focus {
        .MuiAvatar-root {
          border-color: var(--primary-main-color) !important;
          background: var(--primary-main-color) !important;
          svg {
            fill: #fff;
          }
        }
      }
    }
  }
}

/****** links ******/
.tnc-link {
  a {
    &:focus {
      color: var(--link-color);
      border-color: var(--link-color);
      opacity: 0.9;
    }
  }
}

.ngray-link {
  &:focus {
    color: var(--link-color) !important;
    border-color: var(--link-color) !important;
    opacity: 0.9 !important;
  }
}

.back-to-url {
  &:focus {
    box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
  }
}

.dashboard-leftsidebar {
  .dashboard-menus {
    ul {
      li {
        a {
          &:focus {
            color: var(--link-color) !important;
            border-color: var(--link-color) !important;
            opacity: 0.9 !important;
          }
        }
      }
    }
  }
}
.nlink-secondary {
  &:focus {
    opacity: 0.9;
    color: var(--primary-dark-color) !important;
    border-bottom: 2px solid var(--primary-dark-color) !important;
    //outline: 2px solid var(--primary-dark-color) !important;
  }
}
.nlink-secondary-dark {
  &:focus {
    opacity: 0.9;
  }
}
.ngray-link {
  &:focus {
    opacity: 0.9;
    outline: 2px solid var(--primary-dark-color) !important;
  }
}
.nshow-link {
  &:focus {
    opacity: 0.9;
    outline: 2px solid var(--primary-dark-color) !important;
  }
}
.create-alert {
  .subscribe-btn {
    &:focus {
      opacity: 0.9;
      box-shadow: none !important;
      border-radius: initial;
    }
  }
}
.icon-link {
  &:focus {
    opacity: 0.8;
  }
}

.notification-setting {
  &:focus {
    opacity: 0.8;
    outline: 2px solid var(--primary-dark-color) !important;
  }
}

.information-icon {
  &:focus {
    opacity: 0.7;
  }
}

.company-list {
  .company-card {
    .item {
      .content {
        .header-primary {
          .link-focus {
            &:focus {
              border-bottom: 2px solid var(--primary-dark-color);
            }
          }
        }
      }
    }
  }
}

/****** All input and dropdown ******/
.custom-textbox {
  input,
  select {
    &:focus {
      border-color: var(--gray-color) !important;
    }
  }
}
.sort-by {
  .react-custom-dropdown {
    > div {
      &:focus {
        box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
      }
    }
  }
}

/****** radio button ******/
.checkbox-wrap {
  .custom-checkbox {
    margin: 0;
    .MuiCheckbox-root {
      &.Mui-focusVisible {
        box-shadow: 0 0 0px 3px var(--gray-outline-color) !important;
        + span {
          color: var(--primary-dark-color) !important;
        }
      }
    }
  }
}

.custom-checkbox {
  .round-ckeckbox {
    &.Mui-focusVisible {
      box-shadow: 0 0 0px 3px var(--gray-outline-color) !important;
    }
  }
}

.ncheckbox-wrapper {
  .Mui-focusVisible {
    box-shadow: 0 0 0px 3px var(--gray-outline-color) !important;
  }
}

.MuiRadio-root {
  &.Mui-focusVisible {
    svg {
      fill: var(--primary-dark-color);
    }
    + span {
      color: var(--primary-dark-color);
    }
  }
}

/****** Switch toggle button ******/

.ncustom-switch:focus,
.switch-focus {
  > span {
    // outline: 4px solid var(--primary-dark-color) !important;
    box-shadow: 0 0 0px 3px var(--gray-outline-color) !important;
    border-radius: 100px;
    .MuiSwitch-thumb {
      background-color: #fff;
    }
  }
}

/****** Buttons ******/
.MuiButton-root,
button:not(.epr-btn),
a.btn-edit {
  transition: all 0.3s;
  &:focus {
    opacity: 0.9;
    box-shadow: 0 0 0px 3px var(--gray-outline-color) !important;
    border-radius: 45px;
  }
}

.nbg-secondary {
  &:focus {
    opacity: 0.9;
    box-shadow: 0 0 0px 3px var(--gray-outline-color) !important;
    border-radius: 45px;
  }
}

.nlink-btn {
  &:focus {
    opacity: 0.9;
    box-shadow: 0 0 0px 3px var(--gray-outline-color) !important;
    border-radius: 45px;
  }
}

.linkedin-sso-login-v2 {
  .linkedin-sso-login-v2-content {
    &:focus {
      opacity: 0.9;
      box-shadow: 0 0 0px 3px var(--gray-outline-color) !important;
    }
  }
}

.field-wrap {
  .YourBG {
    .edit-link {
      &:focus {
        outline: 2px solid var(--primary-dark-color) !important;
      }
    }
    .no-underline {
      &:focus {
        svg {
          outline: 2px solid var(--primary-dark-color) !important;
        }
      }
    }
  }
}
.widgets-item {
  .no-underline {
    &:focus {
      svg {
        outline: 2px solid var(--primary-dark-color) !important;
      }
    }
  }
}

.action-btns-wrapper {
  .shadow-btn:focus {
    outline: 2px solid var(--primary-dark-color) !important;
  }
}

.linked-in {
  button {
    &:focus {
      outline: 2px solid var(--primary-dark-color) !important;
      box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
    }
  }
}

.MuiCardHeader-action {
  button {
    &:focus {
      opacity: 1;
      background-color: var(--icon_background_hover) !important;
      box-shadow: 0 0 0px 3px var(--gray-outline-color) !important;
    }
  }
}

body {
  .btn-test {
    button {
      &:focus {
        box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
      }
    }
  }
  .main-noti {
    .noti-clear {
      button {
        &:focus {
          box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
        }
      }
    }
  }
}

body {
  #root {
    .shadow-btn,
    .back-to-btn {
      &:focus {
        box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
      }
    }
  }
}

.social-icons {
  a {
    &:focus {
      .MuiAvatar-root {
        opacity: 0.9;
        box-shadow: 0 0 0px 3px var(--gray-outline-color) !important;
      }
      span {
        color: #000c3d !important;
      }
    }
  }
}

.pages-back-btn {
  &:focus {
    box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
  }
}

.webinar-section__header-heading {
  .custom-tab {
    button {
      &:focus {
        box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
      }
    }
  }
}

body {
  .right-section {
    .sub-drodown {
      &:focus {
        box-shadow: 0 0 0px 3px var(--gray-outline-color) !important;
        border-radius: 45px;
      }
    }
  }
}

.custom-options-list {
  a {
    &:focus {
      box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
    }
  }
}
.calendar-card {
  .fc-view-harness {
    a {
      &:focus {
        box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
        border-radius: 45px;
      }
    }
  }
}

.modal-close-icon {
  &:focus {
    box-shadow: 0 0 0px 3px var(--gray-outline-color) !important;
    border-radius: 45px;
  }
}

.user-action {
  &:focus {
    box-shadow: 0 0 0px 3px var(--gray-outline-color) !important;
  }
}

/****** Cards ******/
.dashboard-leftsidebar {
  .save-card {
    .card-content {
      &:focus {
        box-shadow: 0 1px 14px 3px var(--gray-outline-color);
      }
    }
  }
}
body {
  .savedNetwork {
    transition: all 0.3s;
    &:focus {
      box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
    }
    &:hover {
      box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
    }
  }
}

.country-advisory-section,
.country-services-wrapper {
  ul {
    a {
      &:focus {
        .wrapper,
        .wraper {
          box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
        }
      }
    }
  }
}

.mytopic-mainpage {
  .my-topic-page {
    .list {
      .info-panel {
        .info-panel-card {
          &:hover {
            box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
          }
        }
        &:focus {
          .info-panel-card {
            box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
          }
        }
      }
    }
  }
}

.saved-post-card {
  margin-top: 20px;
  &:focus {
    .save-card {
      .card-content {
        box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
      }
    }
  }
}

.card-accessibility-focus {
  &:focus {
    .MuiCard-root {
      box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
    }
    .card-actions-wrapper {
      opacity: 1 !important;
      visibility: visible !important;
    }
    .checklist-card {
      .shadow-btn,
      .btn-edit,
      .btn-close {
        opacity: 1 !important;
        visibility: visible !important;
      }
    }
  }
}

a {
  &:hover,
  &:focus {
    .MuiCard-root {
      box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
    }
  }
}

.dashboard-leftsidebar {
  .card-accessibility-focus {
    .MuiCard-root {
      box-shadow: none !important;
    }
    &:focus {
      .MuiCard-root {
        .card-content {
          box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
        }
      }
    }
  }
  > .card-wrapper {
    .card-accessibility-focus {
      .MuiCard-root {
        .card-content {
          box-shadow: none !important;
        }
      }
    }
  }
}

/****** Accordion ******/
.MuiAccordionSummary-root {
  &.Mui-focusVisible {
    background: transparent;
    .MuiAccordionSummary-content {
      p {
        border-bottom: 1px solid var(--primary-dark-color);
        color: var(--primary-dark-color) !important;
      }
    }
  }
}
.MuiAccordionSummary-root {
  &.Mui-focused {
    box-shadow: 0 1px 14px 3px var(--gray-outline-color);
    background: transparent;
  }
}

.pos-relative {
  &:focus {
    .dashboard-card {
      box-shadow: 0 1px 14px 3px var(--gray-outline-color);
    }
  }
}

/****** accessibility mode ******/
.accessibility {
  #root {
    > div {
      *:not([tabindex="-1"]):focus,
      *:not(.no-outline):focus {
        outline: 2px solid var(--primary-dark-color) !important;
      }
      button.slick-arrow:focus {
        box-shadow: none !important;
      }
      .card-actions-wrapper {
        opacity: 1 !important;
        visibility: visible !important;
      }
      .linked-in.profile-linkedin-container {
        > button {
          outline: 0 !important;
          box-shadow: none !important;
        }
      }
      main {
        #main-page-content {
          div[tabindex="-1"] {
            outline: none !important;
          }
        }
      }
      .react-custom-dropdown input:focus {
        outline: none !important;
      }
    }
    .nlink-secondary {
      &:focus {
        opacity: 0.9;
        outline: 2px solid var(--primary-dark-color) !important;
      }
    }
  }

  .recommended-network-dialog {
    .nlink-btn {
      margin: 5px 5px 5px 10px !important;
      padding: 5px 8px 3px !important;
      height: auto !important;
      opacity: 1 !important;
      transform: scaleY(1) !important;
    }
  }
}

.profile-linkedin-container button {
  outline: none !important;
}

.accessibility-slider {
  button {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    border-radius: 100px;
    margin-top: -8px;
    box-shadow: none !important;
    &:before {
      color: var(--secondary-color);
      position: relative;
      top: 2px;
    }
  }
}

.linked-in.profile-linkedin-container {
  > button {
    outline: 0 !important;
    box-shadow: none !important;
  }
}

.accessibility {
  .info-icon-tooltip-container {
    &:focus {
      outline: 2px solid var(--primary-dark-color) !important;
    }
  }

  .MuiDialogContent-root {
    .sub-header-primary,
    .textbox-label {
      font-family: "TTCommons-Medium" !important;
      font-size: 18px !important;
      font-weight: 500 !important;
      line-height: 19px !important;
    }
  }

  .gm-fullscreen-control,
  .gm-control-active {
    background-color: var(
      --gray-outline-color
    ) !important; /* Dark background for better contrast */
    color: var(--primary-dark-color) !important; /* White icon for contrast */
    border: 2px solid #fff !important; /* Clear boundary */
    padding: 8px;
    border-radius: 5px;
    img {
      filter: invert(100%) brightness(200%);
    }

    &:hover {
      background-color: var(
        --primary-dark-color
      ) !important; /* Change color on hover */
    }
  }

  a:focus,
  a:hover {
    &:after {
      content: none !important;
    }
  }

  .ncustom-switch:focus,
  .switch-focus {
    .MuiSwitch-track {
      outline: 2px solid var(--primary-dark-color) !important;
      outline-offset: 4px !important;
    }
  }
}

.skip-content-link {
  z-index: 1;
  padding: 0;
  text-decoration: none;
  border-radius: 100px;
  font-family: "TTCommons-Medium";
  display: block;
  font-size: 0px;
  color: var(--primary-dark-color);
  text-transform: capitalize;
  opacity: 1;
  font-weight: 500;
  width: fit-content;
  &:focus {
    font-size: 16px;
  }
}

.accessibility-hidden-element {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

.star {
  a:focus,
  a:hover {
    &:after {
      content: "";
      position: absolute;
      box-shadow: 0 0 0px 2px #fff, inset 0 0 10px rgba(209, 221, 229, 0.7) !important;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.read-more-link {
  text-decoration: none;
  &:focus {
    > * {
      outline: 2px solid var(--primary-dark-color) !important;
    }
  }
}
